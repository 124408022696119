.contact-sec {
    background-image: url('../images/contact-sec-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.costum-conatctsec-fluid {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8rem 0;
}

.conatctsec-heading {
    color: var(--special_text_color);
    letter-spacing: var(--special_letter_spacing);
    font-size: 1.5rem;
    font-weight: bold;
}

.contactsec-sub-heading {
    letter-spacing: var(--special_letter_spacing);
    font-size: 3em;
    color: white;
}

.costum-contact-sec {
    width: 40%;
}

.costum-contactsec-container-child-div {
    width: 100%;
}

.contact-sec-content {
    color: rgb(223, 213, 213);
}

@media screen and (max-width: 768px) {
    .contactsec-sub-heading {
        font-size: 1.5em;
    }

    .costum-contact-sec {
        width: 100%;
    }

    .costum-conatctsec-fluid {
        padding: 3rem 0;
    }
}

@media screen and (max-width: 420px) {
    .contactsec-sub-heading {
        font-size: 1.3em;
    }

    .costum-contact-sec {
        width: 100%;
    }

    .costum-conatctsec-fluid {
        padding: 3rem 0;
    }
}
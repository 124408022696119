.career-sec {
    padding: 3rem 0 5em 0;
    background-color: rgb(19, 17, 41);
    color: white;
}

.career-details {
    border: 1px solid #09aff4;
    border-radius: 5px;
    padding: 1em;
}

.career-summary {
    color: #09aff4;
    font-weight: bold;
}

.costum-career-btn {
    width: 8em;
    height: 3em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 1em;
    border-width: 0.15em;
    margin-top: 1em;
    color: white;
    font-weight: bold;
    border-color: #09aff4;
    background-color: #09aff4;
}

.current-opening-icon {
    color: #09aff4;
    margin: 0 49%;
}

@media screen and (max-width: 767px) {
    .current-opening-icon {
        margin: 0 47%;
    }
}
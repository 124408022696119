.solu-strat-sec {
    padding: 5rem 0;
    background-color: rgb(19, 17, 41);
    padding: 2rem 0;
    color: white;
}

.solu-strat-icon {
    font-size: 5rem;
    color: #09aff4;
    /* color: white; */
    text-align: center;
}

.solu-strat-mainDiv {
    /* background-color: #09aff4 !important; */
    background-color: rgb(19, 17, 41) !important;
    border: 1px solid #09aff4;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
    padding: 1rem 0.5rem;
    border-radius: 1rem;
    margin: 1rem 1rem;
}

.solu-strat-row {
    display: flex;
    justify-content: center;
}

.solu-strat-mainDiv:hover {
    background-color: #09aff4 !important;
}

.solu-strat-mainDiv:hover .solu-strat-icon {
    color: white;
    /* Change to white on hover */
}

@media screen and (max-width: 768px) {
    .solu-strat-mainDiv {
        margin: 0.5rem;
        padding: 1rem;
    }

}